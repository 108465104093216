.CreateCampaign {
  overflow-y: auto;
  overflow-x: hidden;
}

.CreateTemplate label {
  text-align: left;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding-top: 2.5rem
}

.CreateTemplate label span {
  color: red;
}

.UpdateFormHeader {
  font-family: "Toyota Type Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: #282830;
}

.UpdateFormHeader span {
  color: red;
}

.UpdateFormSubTitle {
  font-family: "Toyota Type Book";
  font-style: normal;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #282830;
}

.SelectedTemplateHeader {
  font-family: "Toyota Type Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: #282830;
}

.SelectedTemplateSubTitle {
  font-family: "Toyota Type Book";
  font-style: normal;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #282830;
  font-weight: bold;
}

.SelectedTemplateDescription {
  font-family: "Toyota Type Book";
  font-style: normal;
  font-size: 1.6rem;
  line-height: 3.8rem;
  color: #282830;
}

.Select {
  width: 80%;
  margin-top: 2rem;
}

.Input {
  width: 100%;
  margin-top: 1rem;
}

.disabledInput {
  width: 100%;
  margin-top: 1rem;
  opacity: 0.7;
}


.Buttons {
  align-self: flex-end;
  padding-right: 8rem;
  margin-top: 12rem;
  margin-left: 60rem;
}

.Discard {
  align-self: flex-end;
  padding-right: 1rem;
  margin-top: 12rem;
}

.Container {
  display: flex;
}

.TemplatePreview {
  content: "";
  background-color: var(--tme-grey-6-greys);
  border: 1px solid var(--tme-grey-4-greys);
  text-decoration: none;
  /* max-width: 13rem; */
  height: 50rem;
  width: 77rem;
  /* padding-left: 2rem;
  padding-top: 1.5rem; */
}

.TemplateBigPreview {
  height: 67.6rem;
  width: 34rem;
  margin-left: 22.5rem;
}

.SelectTemplateBig {
  margin-left: 2rem;
  margin-top: 2rem;
  height: 45rem;
  width: 72rem;
}

.ChannelContainer {
  margin-top: 1rem;
  text-align: left;
  padding: 0 0rem;
}

.disableContainer {
  margin-top: 1rem;
  text-align: left;
  padding: 0 0rem;
  opacity: 0.7;
  pointer-events: none;
}

.disableUploadWidth {
  opacity: 0.7;
  pointer-events: none;
}

.NavItemSelected {
  background-color: rgba(77, 187, 241, 0.1);
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: Toyota Type Regular;
  cursor: pointer;
  text-align: left;
  padding-left: 1.7rem;
}

.NavItem {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: Toyota Type Regular;
  cursor: pointer;
  text-align: left;
  padding-left: 1.7rem;
}

.NavigationMenu {
  /* position: absolute; */
  width: 24rem;
  left: 137px;
  height: 1634px;
  top: 232px;
  background: #ffffff;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
  padding-left: 1rem;
  margin: -2rem auto auto -5rem;
}

.MenuList {
  position: static;
  font-family: Toyota Type Regular;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #282830;
  margin: 2rem 0rem;
  cursor: pointer;
  text-align: left;
}

.mycol {
  padding-left: 1rem;
}

.NavLink .active {
  background-color: #eef4ff;
}

.CreateTemplate {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
}

.active {
  color: "black";
  background: "#EEF4FF";
}

.inactive {
  color: "black";
}

.MultipickerStyleTemplate {
  grid-template-columns: repeat(2, 1fr);
}

.floatcontainer {
  border: 3px solid #fff;
  width: 100%;
}
.floatcontainer_create{
  border: 3px solid #fff;
  width: 50%;
  float:right
}

.floatchild {
  width: 50%;
  float: left;
  padding: 0px;
  margin-bottom: 5rem;
}

.floatchild1 {
  width: 30%;
  float: left;
  /* padding-right: 10px;
  margin-bottom: 5rem; */
  padding-top: 2rem;
}

.floatchild2 {
  width: 70%;
  float: left;
  padding: 0px;
  margin-bottom: 5rem;
}

.ButtonsEdit {
  align-self: flex-end;
  padding-right: 1rem;
  margin-top: 12rem;
  margin-left: 4rem;
}

.floatchildButton {
  width: 50%;
  float: left;
  padding-top: 2rem;
  margin-bottom: 5rem;
}

.save {
  width: 20%;
  float: left;
  padding: 0px;
  margin-bottom: 15rem;
  /* margin-left: 22rem; */
  margin-left: 3rem;
}

.saveClone {
  width: 20%;
  float: left;
  padding: 0px;
  margin-bottom: 15rem;
  margin-left: 3rem;
}

.saveClose {
  width: 20%;
  float: left;
  margin-bottom: 5rem;
  margin-left: 4rem;
}

.publish {
  width: 32%;
  float: right;
  margin-right: 12rem;
  margin-top: 0.5rem;
}

.publish2 {
  width: 32%;
  float: right;
}

/*search in reusepublish*/

.SearchBox {
  width: 19rem;
  height: 3.6rem;
  margin-right: 2rem;
  position: relative;
  z-index: 0;
}

.SearchBox input {
  font-size: 1.2rem;
  line-height: 1.38rem;
  font-weight: 400;
  /*background: var(--ct-background-grey);*/
  width: 100%;
  height: 100%;
  padding-left: 1.8rem;
  /* border: .1rem solid var(--tme-grey-6-greys); */
  border: 0.1rem solid #CECFD0;
  border-radius: 1.8rem;
  outline: none;
}

.SearchBox::after {
  content: url("../../../assets/icons/common_search.svg");
  position: absolute;
  z-index: 1;
  right: .9rem;
  top: .9rem;
}

.ControlBox {
  display: flex;
  align-items: center;
  margin-right: 2rem;
  margin-bottom: 4rem;
}

/*Edit Template delete or download*/
.TemplateDeleteDownload {
  font-family: 'Toyota Type Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #5592FF;
  cursor: pointer;
}

.TemplateDeleteDownloadView {
  font-family: 'Toyota Type Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #5592FF;
  opacity: 0.7;
  pointer-events: none;
}

.ParentContainer {
  display: flex;
}

.child1 {
  flex: 1;
}

.child2 {
  width: 50%;
  float: right;
  padding: 20px;
}

.BigSvg {
  height: 30%;
  width: 30%;
  padding-left: 35rem;
}

.CreateTemplateModule1 {
  background-color: var(--app-white);
  min-height: calc(190rem - var(--content-header-height) + 1rem);
  height: auto;
  border-radius: 0.1rem;
  box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.15);
  padding: 3.1rem 0 0 3rem;
  /* display: grid; */
  /* grid-auto-columns: 20rem 1fr;
  grid-template-areas: "LeftNav RightContent"; */
}

/* Edit Template styles */
.RightContentEditTemplate {
  grid-column: 2;
  /* padding-top: 2rem; */
  /* border: 1px solid black; */
  /* padding-left: 5rem; */
  padding-right: 4rem;
  text-align: left;
}

.CreateTemplateModule1 label {
  text-align: left;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding-top: 2.5rem
}

.CreateTemplateModule1 label span {
  color: red;
}